import React from 'react'

import { formatDateOrQuarter } from '~/components/utils/dateFormatter'
import { Heading2, Heading4, SmallText } from '@entur/typography'
import { ExpandableTextButton } from '@entur/expand'

import { ChangeType } from '~/components/Changes/ChangeTypes'
import { ReactMarkdownOrHtml } from '~/components/Changes/ReactMarkdownOrHtml'
import {
    ChangeTypeFilters,
    allChangeTypeFilterStates,
    TypeFilterStates,
} from '~/components/Changes/ChangeTypeFilters'
import {
    ChangeTypeStrings,
    getSortedAndPrioritizedChangeTypes,
    toChangeType,
} from '~/components/utils/changeTypeHelpers'

import './Plans.scss'

type PlannedChangeProps = {
    team?: string
    api_service?: string
    /** Will not be used if types field is used **/
    type?: string
    /** If present, this will be override anything passed in from type field **/
    types?: string[]
    title?: string
    date?: string
    jira_issue?: string
    description: string
}

type NormalisedPlannedChange = {
    team?: string
    api_service?: string
    types: ChangeTypeStrings[]
    title?: string
    date?: string
    jira_issue?: string
    description: string
}

const PlannedChange: React.FC<PlannedChangeProps> = (plan) => {
    const types = getSortedAndPrioritizedChangeTypes(plan)

    return (
        <div className="plan__grid">
            <SmallText className="plan__date">
                {formatDateOrQuarter(plan.date)}
            </SmallText>
            <SmallText className="plan__service"> {plan.api_service}</SmallText>
            <div className="plan__changetypes">
                {types.map((type, index) => (
                    <ChangeType
                        changeType={type}
                        className="plan__changetype"
                        key={index}
                    >
                        {' '}
                    </ChangeType>
                ))}
            </div>
            <Heading4 margin="none" className="plan__title">
                {plan.title}
            </Heading4>
            <ReactMarkdownOrHtml
                content={plan.description}
                className="plan__description"
            />
        </div>
    )
}

export const parsePlans = (
    plans: PlannedChangeProps[],
): NormalisedPlannedChange[] => {
    return plans.map((change) => {
        if (change.types) {
            return {
                ...change,
                types: change.types?.map((type) => toChangeType(type)),
            }
        } else if (change.type) {
            return {
                ...change,
                types: [toChangeType(change.type)],
            }
        } else {
            return {
                ...change,
                types: ['improvement'],
            }
        }
    })
}

export const filterChanges = (
    changes: NormalisedPlannedChange[],
    typeFilter: TypeFilterStates,
): NormalisedPlannedChange[] =>
    changes.filter((change) => {
        for (const type of change.types) {
            if (typeFilter[type]) return true
        }
        return false
    })

type PlannedChangeListProps = {
    plansData: {
        plans?: PlannedChangeProps[]
    }
}

const PlannedChangeList: React.FC<PlannedChangeListProps> = ({ plansData }) => {
    const [typeFilter, setTypeFilter] = React.useState(
        allChangeTypeFilterStates,
    )
    const [showAllPlans, setShowAllPlans] = React.useState(false)
    if (!plansData.plans || !plansData.plans.length) {
        return null
    }
    const normalisedPlans = parsePlans(plansData.plans)
    const filteredChanges = filterChanges(normalisedPlans, typeFilter)

    return (
        <div className="plans">
            <Heading2 className="plans__header plans__header-margin">
                Plans
            </Heading2>
            <ChangeTypeFilters
                typeFilter={typeFilter}
                setTypeFilter={setTypeFilter}
            />
            {filteredChanges.slice(0, 3).map((plan, index) => (
                <PlannedChange key={index} {...plan} />
            ))}
            {showAllPlans &&
                filteredChanges
                    .slice(3)
                    .map((plan, index) => (
                        <PlannedChange key={index} {...plan} />
                    ))}

            {filteredChanges.length > 3 ? (
                <div className="plans__see-more">
                    <ExpandableTextButton
                        open={showAllPlans}
                        onToggle={() => setShowAllPlans((prev) => !prev)}
                    >
                        {showAllPlans ? 'See less' : 'See more'}
                    </ExpandableTextButton>
                </div>
            ) : (
                <div className="plans__see-more--hidden" />
            )}
        </div>
    )
}

export default PlannedChangeList

import { Link, ListItem, NumberedList } from '@entur/typography';
import { CodeText } from '@entur/typography';
import "../../../../../pages/offers/docs/guides.scss";
import * as React from 'react';
export default {
  Link,
  ListItem,
  NumberedList,
  CodeText,
  React
};
import React from 'react'

import { SearchIcon } from '@entur/icons'

import { SearchableDropdown } from '@entur/dropdown'

async function getItems(query) {
    if (!query) {
        return []
    }

    const url = `https://api.entur.io/geocoder/v1/autocomplete?text=${query}`
    const response = await fetch(url, {
        headers: { 'ET-Client-Name': 'entur-docs' },
    })

    const { features } = await response.json()

    return features.map((feature) => ({
        ...feature,
        label: feature.properties.label,
    }))
}

export default function AutosuggestField(props) {
    const { onSelected, ...rest } = props

    return (
        <SearchableDropdown
            {...rest}
            items={getItems}
            debounceTimeout={400}
            onChange={(feature) => {
                onSelected(feature)
            }}
            noMatchesText="No matches found"
            prepend={<SearchIcon />}
            clearable={false}
        />
    )
}

import { Paragraph } from '@entur/typography';
import { BannerAlertBox } from '@entur/alert';
import { NavigationCard } from '@entur/layout';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
import * as React from 'react';
export default {
  Paragraph,
  BannerAlertBox,
  NavigationCard,
  Table,
  TableHead,
  TableBody,
  TableRow,
  HeaderCell,
  DataCell,
  React
};
import React from 'react'
import { Tag } from '@entur/layout'

import D from './icons/D.svg'
import GA from './icons/GA.svg'
import P from './icons/P.svg'
import S from './icons/S.svg'
import VY from './icons/VY.svg'
import { Tooltip } from '@entur/tooltip'

import './Environments.scss'

export type EnvironmentsProps = {
    environments?: string
}

export const Environments: React.FC<EnvironmentsProps> = ({ environments }) => {
    const environmentsPreferredOrder = ['dev', 'staging', 'prod']
    const environmentList = environments
        ? environments.replace(/:/g, '').split(' ')
        : []
    return (
        <>
            {environmentsPreferredOrder.map((environment, index) => {
                if (environmentList.includes(environment)) {
                    switch (environment) {
                        case 'dev':
                            return <DevelopmentEnvironment key={index} />
                        case 'staging':
                            return <StagingEnvironment key={index} />
                        case 'prod':
                            return <ProductionEnvironment key={index} />
                        case 'vy-dev':
                            return <VYDevEnvironment key={index} />
                        case 'ga-dev':
                            return <GADevEnvironment key={index} />
                        default:
                            return null
                    }
                }
            })}
        </>
    )
}

const DevelopmentEnvironment = () => {
    return (
        <Tooltip content="Development environment" placement="bottom">
            <span>
                <Tag className="environment">
                    <img src={D} alt="Development environment" />
                </Tag>
            </span>
        </Tooltip>
    )
}

const StagingEnvironment = () => {
    return (
        <Tooltip content="Staging environment" placement="bottom">
            <span>
                <Tag className="environment">
                    <img src={S} alt="Staging environment" />
                </Tag>
            </span>
        </Tooltip>
    )
}

const ProductionEnvironment = () => {
    return (
        <Tooltip content="Production environment" placement="bottom">
            <span>
                <Tag className="environment">
                    <img src={P} alt="Production environment" />
                </Tag>
            </span>
        </Tooltip>
    )
}

const VYDevEnvironment = () => {
    return (
        <Tooltip content="VY development environment" placement="bottom">
            <span>
                <Tag className="environment">
                    <img src={VY} alt="VY development environment" />
                </Tag>
            </span>
        </Tooltip>
    )
}

const GADevEnvironment = () => {
    return (
        <Tooltip content="Go-Ahead development environment" placement="bottom">
            <span>
                <Tag className="environment">
                    <img src={GA} alt="Go-Ahead development environment" />
                </Tag>
            </span>
        </Tooltip>
    )
}

import "../../../../../pages/intro/setup-and-access.scss";
import { Paragraph, SmallText, NumberedList, LeadParagraph, ListItem, Link, StrongText, Heading4, UnorderedList } from '@entur/typography';
import IconCircle from '~/components/IconCircle/IconCircle';
import * as React from 'react';
export default {
  Paragraph,
  SmallText,
  NumberedList,
  LeadParagraph,
  ListItem,
  Link,
  StrongText,
  Heading4,
  UnorderedList,
  IconCircle,
  React
};
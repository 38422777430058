import React, { FC } from 'react'

import { useToast } from '@entur/alert'
import { NegativeButton } from '@entur/button'

import { useSubscribeErrorContext } from './ToastErrorContext'

export const UnsubscribeButton: FC = () => {
    const { addToast } = useToast()
    const subscribeErrorContext = useSubscribeErrorContext()
    const getNotificationChannelPath = () => {
        const environment = window.location.host

        switch (environment) {
            case 'developer.entur.org':
                return 'https://api.entur.io/notification-channel/v1'
            case 'developer.staging.entur.org':
                return 'https://api.staging.entur.io/notification-channel/v1'
            default:
                return 'https://api.dev.entur.io/notification-channel/v1'
        }
    }

    const handleClick = async () => {
        try {
            subscribeErrorContext.setError(null)
            const queryParams = new URLSearchParams(window.location.search)
            const uuid = queryParams.get('uuid')
            if (uuid === null) {
                subscribeErrorContext.setError({
                    title: 'Failed to unsubscribe',
                    content: `Value for uuid is missing`,
                })
                return
            }

            const url =
                getNotificationChannelPath() +
                '/subscribers/' +
                encodeURIComponent(uuid)
            const response = await fetch(url, {
                method: 'DELETE',
            })
            if (response.ok) {
                addToast({
                    title: 'Your subscription is canceled',
                    content: null,
                })
            } else {
                switch (response.status) {
                    case 400:
                        subscribeErrorContext.setError({
                            title: 'Failed to unsubscribe',
                            content: `Input is not correct. Please validate that your uuid is formatted correctly.`,
                        })
                        break
                    case 404:
                        subscribeErrorContext.setError({
                            title: 'Failed to unsubscribe',
                            content: `Subscription may already have been unsubscribed or passed in uuid is not valid.`,
                        })
                        break
                    default:
                        subscribeErrorContext.setError({
                            title: 'Failed to unsubscribe',
                            content: `Please retry. If the problem persist contact support@entur.org`,
                        })
                }
            }
        } catch (err) {
            console.log(err)
            subscribeErrorContext.setError({
                title: 'Failed to unsubscribe',
                content: `Please retry. If the problem persist contact support@entur.org`,
            })
        }
    }

    return <NegativeButton onClick={handleClick}>Unsubscribe</NegativeButton>
}

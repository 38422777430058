import './ToastErrorContext.scss'

import React, { FC, useContext, useState } from 'react'

import { SmallAlertBox, SmallExpandableAlertBox } from '@entur/alert'

export interface ErrorDescription {
    title: string
    content: string
}

interface SubscribeError {
    isFailed: boolean
    error: ErrorDescription | null
    setError: (error: ErrorDescription | null) => void
}

const SubscribeErrorContext = React.createContext<SubscribeError>({
    isFailed: false,
    error: null,
    setError() {
        // Empty. Method wil get implementation in provider function below.
    },
})

export function useSubscribeErrorContext(): SubscribeError {
    return useContext(SubscribeErrorContext)
}

export const SubscribeErrorProvider: FC = ({ children }) => {
    function setErrorHandler(error: ErrorDescription | null) {
        setErrorState({
            isFailed: error !== null,
            error: error,
            setError: setErrorHandler,
        })
    }

    const [errorState, setErrorState] = useState<SubscribeError>({
        isFailed: false,
        error: null,
        setError: setErrorHandler,
    })
    return (
        <>
            {errorState.isFailed && !errorState.error?.content && (
                <div className="toast-error-context-margin">
                    <SmallAlertBox variant="error">
                        {errorState.error?.title}
                    </SmallAlertBox>
                </div>
            )}
            {errorState.isFailed && errorState.error?.content && (
                <div className="toast-error-context-margin">
                    <SmallExpandableAlertBox
                        variant="error"
                        title={errorState.error?.title}
                    >
                        {errorState.error?.content}
                    </SmallExpandableAlertBox>
                </div>
            )}
            <SubscribeErrorContext.Provider value={errorState}>
                {children}
            </SubscribeErrorContext.Provider>
        </>
    )
}

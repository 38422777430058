import { Octokit, RestEndpointMethodTypes } from '@octokit/rest'

import { ChangelogProps } from '../components/Changes/Changelog'
import { ChangeProps } from '../components/Changes/ChangelogEntry'

const octokit = new Octokit()

type Releases =
    RestEndpointMethodTypes['repos']['listReleases']['response']['data']

type Options = {
    owner: string
    repo: string
    repoName?: string
    team?: string
    changelogPage?: string
}

function releasesToChangeData(
    releases: Releases,
    options: Options,
): ChangelogProps['changelogData'] {
    const { repo, repoName, team, changelogPage } = options
    const nameOfRepo = repoName || repo || ''

    const changes: ChangeProps[] = releases.map((release) => ({
        version: [nameOfRepo, release.name || '?.?.?'].join(' ').trim(),
        date: release.created_at.slice(0, 10),
        description: release.body || '',
    }))
    return {
        changes,
        team,
        changelog_page: changelogPage,
    }
}

export async function getChangelogFromGithubReleases(
    options: Options,
): Promise<ChangelogProps['changelogData']> {
    const { owner, repo } = options
    const { data } = await octokit.repos.listReleases({
        owner,
        repo,
    })
    return releasesToChangeData(data, options)
}

import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Link } from '@entur/typography'

export type ReactMarkdownOrHtmlProps = {
    content: string
    className?: string
}

export const ReactMarkdownOrHtml: React.FC<ReactMarkdownOrHtmlProps> = ({
    content,
    className,
}) => {
    if (hasHtmlTags(content)) {
        return (
            <DangerouslySetInnerHTML content={content} className={className} />
        )
    }

    return (
        <div className={className}>
            <ReactMarkdown
                components={{
                    a: Link,
                }}
            >
                {content}
            </ReactMarkdown>
        </div>
    )
}

export function hasHtmlTags(content: string): boolean {
    return /<\/?[a-z][\s\S]*>/i.test(content)
}

const DangerouslySetInnerHTML: React.FC<ReactMarkdownOrHtmlProps> = ({
    content,
    className,
}) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

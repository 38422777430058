import { format, isValid } from 'date-fns'

export const formatDateOrQuarter = (dateOrQuarter?: string): string | null => {
    if (!dateOrQuarter) {
        return null
    }

    const date = new Date(dateOrQuarter)

    if (!isValid(date)) {
        return dateOrQuarter
    }

    if (isFullYearAndDate(dateOrQuarter)) {
        return format(date, 'MMM do yyyy')
    }

    if (isFullYearAndMonth(dateOrQuarter)) {
        return format(date, 'MMM yyyy')
    }

    return dateOrQuarter
}

function isFullYearAndDate(dateOrQuarter: string): boolean {
    return dateOrQuarter.length === 10
}

function isFullYearAndMonth(dateOrQuarter: string): boolean {
    return dateOrQuarter.length === 7
}

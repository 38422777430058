import "../../../../../pages/notFoundPage/notFoundPage.scss";
import notFoundImage from "../../../../../pages/notFoundPage/notFoundImage.jpg";
import { PrimaryButton } from '@entur/button';
import { Paragraph, Heading1, Link } from '@entur/typography';
import * as React from 'react';
export default {
  notFoundImage,
  PrimaryButton,
  Paragraph,
  Heading1,
  Link,
  React
};
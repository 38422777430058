import React, { useState } from 'react'

import { CopyableText } from '@entur/alert'
import { FilterChip } from '@entur/chip'

import { DatePicker, TimePicker } from '@entur/datepicker'

import {
    Heading1,
    Heading2,
    Heading3,
    LeadParagraph,
    SubParagraph,
} from '@entur/typography'

import {
    Switch,
    SegmentedControl,
    SegmentedChoice,
    Fieldset,
    Checkbox,
} from '@entur/form'

import { now } from '@internationalized/date'

import AutosuggestField from '~/components/AutosuggestField'

import './travelLinkDoc.scss'

const FILTERS = [
    { value: 'rail', label: 'Train' },
    { value: 'metro', label: 'Metro' },
    { value: 'tram', label: 'Tram' },
    { value: 'bus', label: 'Bus' },
    { value: 'coach', label: 'Express Bus' },
    { value: 'water', label: 'Boat' },
    { value: 'car_ferry', label: 'Car Ferry' },
    { value: 'flytog', label: 'Airport train' },
    {
        value: 'flybuss',
        label: 'Airport bus',
    },
    { value: 'air', label: 'Plane' },
]

export default function TravelLinkDoc() {
    const [departureCheckbox, setDepartureCheckbox] = useState(false)
    const [arrivalCheckbox, setArrivalCheckbox] = useState(false)
    const [departureDestination, setDepartureDestination] = useState(null)
    const [arrivalDestination, setArrivalDestination] = useState(null)

    const [
        defaultMeansOfTransportationSwitch,
        setDefaultMeansOfTransportationSwitch,
    ] = useState(false)

    const [dateTimeSwitch, setDateTimeSwitch] = useState(false)

    const [selectedDateTimeSegment, setSelectedDateTimeSegment] =
        useState('depart')

    const defaultTransportationFilters = FILTERS.map((filter) => filter.value)

    const [transportationFilters, setTransportationFilters] = useState(
        defaultTransportationFilters,
    )

    const toggleFilter = (transportMode) => {
        setTransportationFilters((prevFilters) => {
            if (prevFilters.includes(transportMode)) {
                return prevFilters.filter(
                    (existingTransportMode) =>
                        existingTransportMode !== transportMode,
                )
            } else {
                return [...prevFilters, transportMode]
            }
        })
    }

    const [date, setDate] = useState(now('Europe/Oslo'))
    const [time, setTime] = useState(now('Europe/Oslo'))

    const travelUrl = () => {
        if (!departureDestination && !arrivalDestination)
            return 'https://entur.no'

        const dateObject = date.toDate()
        const timeObject = time.toDate()

        const combinedDateTime = new Date(
            dateObject?.getFullYear(),
            dateObject?.getMonth(),
            dateObject?.getDate(),
            timeObject?.getHours(),
            timeObject?.getMinutes(),
        ).getTime()

        const parameters = {
            startLabel: departureCheckbox ? departureDestination?.label : null,
            startLat: departureCheckbox
                ? departureDestination?.geometry.coordinates[1]
                : null,
            startLon: departureCheckbox
                ? departureDestination?.geometry.coordinates[0]
                : null,
            stopLabel: arrivalCheckbox ? arrivalDestination?.label : null,
            stopLat: arrivalCheckbox
                ? arrivalDestination?.geometry.coordinates[1]
                : null,
            stopLon: arrivalCheckbox
                ? arrivalDestination?.geometry.coordinates[0]
                : null,

            transportModes: defaultMeansOfTransportationSwitch
                ? transportationFilters.join(',')
                : null,
            date: dateTimeSwitch ? combinedDateTime : null,
            timepickerMode:
                selectedDateTimeSegment === 'depart'
                    ? 'departAfter'
                    : 'arriveBefore',
        }

        const queryString = Object.entries(parameters)
            .filter(([, value]) => value !== null && value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&')

        return `https://entur.no/reiselenke?${queryString}`
    }

    const url = travelUrl()

    return (
        <div className="travelLink__body">
            <div className="travelLink__section">
                <Heading1>Travel link</Heading1>
                <LeadParagraph className="travelLink__leadParagraph">
                    {`A customised link that directs the user to a specific travel search. 
                    You choose which data to pre-fill. And it's up to you how you'd like to incorporate the link on your website.`}
                </LeadParagraph>
            </div>
            <div className="travelLink__section">
                <Heading2>Generate travel link</Heading2>
                <Fieldset label="What part of the travel route should be pre-filled?">
                    <SubParagraph className="travelLink__fieldsetSubParagraph">
                        {`If you choose to pre-fill only one, we'll try to fill
                        the other with the user's position. If the user doesn't
                        share their position, they must enter an address.`}
                    </SubParagraph>
                    <Checkbox
                        name="departure"
                        checked={departureCheckbox}
                        onChange={() =>
                            setDepartureCheckbox(!departureCheckbox)
                        }
                    >
                        Departure (from)
                    </Checkbox>
                    {departureCheckbox && (
                        <AutosuggestField
                            className="travelLink__autoSuggest"
                            label="Choose place of departure"
                            onSelected={(feature) =>
                                setDepartureDestination(feature)
                            }
                        />
                    )}
                    <Checkbox
                        name="arrival"
                        checked={arrivalCheckbox}
                        onChange={() => setArrivalCheckbox(!arrivalCheckbox)}
                    >
                        Arrival (to)
                    </Checkbox>
                    {arrivalCheckbox && (
                        <AutosuggestField
                            label="Choose place of arrival"
                            onSelected={(feature) =>
                                setArrivalDestination(feature)
                            }
                        />
                    )}
                </Fieldset>
            </div>
            <div className="travelLink__section">
                <div className="travelLink__flexContainer">
                    <Heading3>Means of transportation</Heading3>
                    <Switch
                        className="travelLink__switch"
                        checked={defaultMeansOfTransportationSwitch}
                        onChange={() =>
                            setDefaultMeansOfTransportationSwitch(
                                !defaultMeansOfTransportationSwitch,
                            )
                        }
                    />
                </div>
                {defaultMeansOfTransportationSwitch && (
                    <div className="travelLink__filterChipGroup">
                        {FILTERS.map((filter) => (
                            <FilterChip
                                className="travelLink__filterChip"
                                value={filter.value}
                                key={filter.value}
                                onChange={() => toggleFilter(filter.value)}
                                checked={transportationFilters.includes(
                                    filter.value,
                                )}
                            >
                                {filter.label}
                            </FilterChip>
                        ))}
                    </div>
                )}
            </div>
            <div className="travelLink__section">
                <div className="travelLink__flexContainer">
                    <Heading3>Date and time</Heading3>
                    <Switch
                        className="travelLink__switch"
                        checked={dateTimeSwitch}
                        onChange={() => setDateTimeSwitch(!dateTimeSwitch)}
                    />
                </div>
                {dateTimeSwitch && (
                    <div>
                        <SegmentedControl
                            onChange={(selectedValue) =>
                                setSelectedDateTimeSegment(selectedValue)
                            }
                            selectedValue={selectedDateTimeSegment}
                            className="travelLink__segmentedControl"
                            size="large"
                        >
                            <SegmentedChoice value="depart">
                                Depart after
                            </SegmentedChoice>
                            <SegmentedChoice value="arrive">
                                Arrive before
                            </SegmentedChoice>
                        </SegmentedControl>
                        <div className="travelLink__flexContainer">
                            <DatePicker
                                className="travelLink__datePicker"
                                label="Date"
                                selectedDate={date}
                                onChange={(date) => setDate(date)}
                                locale="no-NO"
                            />
                            <TimePicker
                                label="Time"
                                selectedTime={time}
                                onChange={(time) => setTime(time)}
                                locale="no-NO"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="travelLink__section">
                <Heading3>Your travel link</Heading3>
                <CopyableText
                    successHeading="Copied to your link!"
                    successMessage="Paste it from your clipboard wherever you need it."
                >
                    {url}
                </CopyableText>
            </div>
        </div>
    )
}

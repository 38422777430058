import "../../../../../pages/intro/authentication.scss";
import { Paragraph, PreformattedText, NumberedList, ListItem, UnorderedList } from '@entur/typography';
import * as React from 'react';
export default {
  Paragraph,
  PreformattedText,
  NumberedList,
  ListItem,
  UnorderedList,
  React
};
import './ChangelogSubscription.scss'

import React, { useState, useEffect, FC } from 'react'
import { PrimaryButton } from '@entur/button'
import { TextField, Fieldset, Checkbox, VariantType } from '@entur/form'
import { EmailIcon } from '@entur/icons'
import { Modal } from '@entur/modal'
import { Heading3, Heading5, Paragraph, Label } from '@entur/typography'
import { BannerAlertBox, useToast } from '@entur/alert'
import { GridContainer, GridItem } from '@entur/grid'
import groupBy from 'lodash.groupby'

import isValidEmail from '../utils/emailValidationHelper'
import { Category, translateCategoryGroup } from './category'

type SubscriptionModalTriggerProps = {
    setShowModal: (showModal: boolean) => void
    subscriptionImage: React.Component
}

const SubscriptionModalTrigger: FC<SubscriptionModalTriggerProps> = ({
    setShowModal,
    subscriptionImage,
}) => {
    return (
        <div className="subscription-trigger">
            <GridContainer>
                <GridItem small={12} medium={5}>
                    <GridContainer>
                        <GridItem small={12}>
                            <Heading3 margin="bottom">
                                Subscribe to API updates
                            </Heading3>
                        </GridItem>
                        <GridItem small={12}>
                            <Paragraph margin="bottom">
                                You can now subscribe and receive emails when
                                new changes are made to API’s.
                            </Paragraph>
                        </GridItem>
                        <GridItem small={7}>
                            <PrimaryButton
                                onClick={() => setShowModal(true)}
                                aria-label="Open subscription modal"
                                width="fluid"
                                className="subscription-modal__open-button"
                            >
                                Subscribe
                            </PrimaryButton>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem className="subscriber-image" small={12} medium={7}>
                    {subscriptionImage}
                </GridItem>
            </GridContainer>
        </div>
    )
}

type ChangelogSubscriptionProps = {
    subscriptionImage: React.Component
}

const ChangelogSubscription: FC<ChangelogSubscriptionProps> = ({
    subscriptionImage,
}) => {
    const [showModal, setShowModal] = useState(false)
    const { addToast } = useToast()
    const [subscriptionCategories, setSubscriptionCategories] = useState<
        Category[]
    >([])
    const [selectedCategories, setSelectedCategories] = useState<number[]>([])
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [variant, setVariant] = useState<VariantType | undefined>(undefined)
    const [feedback, setFeedback] = useState<string | undefined>(undefined)

    async function fetchCategories(url: string) {
        const res = await fetch(url)
        return res.json()
    }

    const getNotificationChannelPath = () => {
        const environment = window.location.host

        switch (environment) {
            case 'developer.entur.org':
                return 'https://api.entur.io/notification-channel/v1'
            case 'developer.staging.entur.org':
                return 'https://api.staging.entur.io/notification-channel/v1'
            default:
                return 'https://api.dev.entur.io/notification-channel/v1'
        }
    }

    useEffect(() => {
        const notificationChannelPath = getNotificationChannelPath()
        fetchCategories(`${notificationChannelPath}/categories`).then(
            (categories) => {
                const activeCategories = categories.filter(
                    (category: Category) => category.active,
                )
                setSubscriptionCategories(activeCategories)
            },
        )
    }, [])

    const isEverythingSelected = subscriptionCategories.every(
        (category: Category) =>
            selectedCategories.some((selected) => selected === category.id),
    )

    const isNothingSelected = subscriptionCategories.every(
        (category: Category) =>
            !selectedCategories.some((selected) => selected === category.id),
    )

    const isSomeSelected = !isEverythingSelected && !isNothingSelected

    const handleAllOrNothingChange = () => {
        if (isEverythingSelected) {
            setSelectedCategories([])
        } else {
            const allCategories = subscriptionCategories.map(
                (categories) => categories.id,
            )
            setSelectedCategories(allCategories)
        }
    }

    const handleRegularChange = (category: Category, isChecked: boolean) => {
        if (!isChecked) {
            setSelectedCategories([...selectedCategories, category.id])
        } else {
            const unselectedCategories = selectedCategories.filter(
                (selected) => selected !== category.id,
            )
            setSelectedCategories(unselectedCategories)
        }
    }

    const submitSubscription = async (event: React.FormEvent) => {
        event.preventDefault()
        const notificationChannelPath = getNotificationChannelPath()

        const payload = {
            active: true,
            email,
            categories: selectedCategories,
        }

        setLoading(true)
        await fetch(`${notificationChannelPath}/subscribers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    setIsError(true)
                    throw Error(response.statusText)
                }
                addToast({
                    title: 'Subscription was registered',
                    content: `A confirmation email is sent to the provided address`,
                })
                setShowModal(false)
                setSelectedCategories([])
                setEmail('')
            })
            .catch(() => {
                setIsError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const groupedSubscriptionCategories = groupBy(
        subscriptionCategories,
        (category: Category) => category.categoryGroup,
    )

    return (
        <>
            <SubscriptionModalTrigger
                setShowModal={setShowModal}
                subscriptionImage={subscriptionImage}
            />
            <Modal
                closeLabel="Close"
                open={showModal}
                title="Subscribe for e-mail updates"
                size="medium"
                onDismiss={() => {
                    setShowModal(false)
                }}
            >
                <Paragraph>
                    Please fill in your email below to receive status updates
                    about our APIs.
                </Paragraph>
                <form onSubmit={submitSubscription}>
                    <TextField
                        prepend={<EmailIcon />}
                        label="E-mail address"
                        value={email}
                        variant={variant}
                        feedback={feedback}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.currentTarget.value)
                            if (variant == 'error' && isValidEmail(email)) {
                                setVariant(undefined)
                                setFeedback('')
                            }
                        }}
                        onBlur={() => {
                            if (!isValidEmail(email)) {
                                setVariant('error')
                                setFeedback(
                                    'Please enter a valid email address',
                                )
                            }
                        }}
                    />
                    <Heading5>
                        Please select the APIs you would like to receive updates
                        for
                    </Heading5>
                    {subscriptionCategories.length > 0 && (
                        <Fieldset>
                            <div className="changelog-subscription__row">
                                <Checkbox
                                    name="Select all"
                                    checked={
                                        isSomeSelected
                                            ? 'indeterminate'
                                            : isEverythingSelected
                                    }
                                    onChange={handleAllOrNothingChange}
                                >
                                    Select all
                                </Checkbox>
                            </div>
                            {Object.entries(groupedSubscriptionCategories).map(
                                ([categoryGroup, categories]) => {
                                    return (
                                        <React.Fragment key={categoryGroup}>
                                            <div className="changelog-subscription__row changelog-subscription__category-group-header">
                                                <Label>
                                                    {translateCategoryGroup(
                                                        categoryGroup,
                                                    )}
                                                </Label>
                                            </div>
                                            {categories.map((category) => {
                                                const isChecked =
                                                    !!selectedCategories?.find(
                                                        (selected) =>
                                                            selected ===
                                                            category.id,
                                                    )
                                                return (
                                                    <div
                                                        key={category.id}
                                                        className="changelog-subscription__row"
                                                    >
                                                        <Checkbox
                                                            checked={isChecked}
                                                            onChange={() =>
                                                                handleRegularChange(
                                                                    category,
                                                                    isChecked,
                                                                )
                                                            }
                                                        >
                                                            {category.title}
                                                        </Checkbox>
                                                    </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                },
                            )}
                        </Fieldset>
                    )}
                    <PrimaryButton
                        className="changelog-subscription__submit-button"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                    >
                        Subscribe
                    </PrimaryButton>

                    {!isValidEmail(email) && isError && (
                        <BannerAlertBox
                            className="changelog-subscription__error"
                            title="Invalid Email"
                            variant="error"
                        >
                            Registration failed
                        </BannerAlertBox>
                    )}
                    {selectedCategories.length === 0 &&
                        isValidEmail(email) &&
                        isError && (
                            <BannerAlertBox
                                className="changelog-subscription__error"
                                title="No categories selected"
                                variant="error"
                            >
                                Registration failed
                            </BannerAlertBox>
                        )}
                    {selectedCategories.length > 0 &&
                        isValidEmail(email) &&
                        isError && (
                            <BannerAlertBox
                                className="changelog-subscription__error"
                                title="Subscription registration failed"
                                variant="error"
                            >
                                Registration failed
                            </BannerAlertBox>
                        )}
                </form>
            </Modal>
        </>
    )
}

export default ChangelogSubscription

import React from 'react'
import { DownloadIcon } from '@entur/icons'

import './DownloadLink.scss'

type DownloadLinkProps = {
    href: string
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ href }) => {
    return (
        <a className="download-link" href={href} download>
            <DownloadIcon />
        </a>
    )
}

export default DownloadLink

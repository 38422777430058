import "../../../../../pages/intro/overview.scss";
import { Paragraph, Heading4, Heading2, StrongText, UnorderedList, ListItem, Link, LeadParagraph } from '@entur/typography';
import { ExpandablePanel } from '@entur/expand';
import tavla from "../../../../../pages/intro/overview-tavla.svg";
import IconCircle from '~/components/IconCircle/IconCircle';
import * as React from 'react';
export default {
  Paragraph,
  Heading4,
  Heading2,
  StrongText,
  UnorderedList,
  ListItem,
  Link,
  LeadParagraph,
  ExpandablePanel,
  tavla,
  IconCircle,
  React
};
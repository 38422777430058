import React, { useState, useEffect } from 'react'

type FetchProps = {
    url: string
    parse: (text: string) => any
    children?: any
}

const Fetch: React.FC<FetchProps> = ({ url, parse, children }) => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        const load = async () => {
            const response = await fetch(url, { mode: 'cors' })
            let parsed
            if (parse) {
                const text = await response.text()
                parsed = parse(text)
            } else {
                parsed = await response.json()
            }
            setData(parsed)
            setLoading(false)
        }
        load()
    }, [url, parse])

    return <>{children({ isLoading, data })}</>
}

export default Fetch

import React from 'react'

import { ExpandablePanel } from '@entur/expand'
import { Heading2, Heading3, Link } from '@entur/typography'
import {
    PricelistIcon,
    ShoppingCartIcon,
    OrganizationIcon,
    ClosedLockIcon,
    InternationalIcon,
    PositionIcon,
    SeatIcon,
    RightArrowIcon,
    ValidTicketIcon,
    MapPinIcon,
    TrainIcon,
    FileIcon,
    HomeIcon,
    CityIcon,
    DiffIcon,
    CardIcon,
    DatabaseIcon,
    PackageIcon,
    MapIcon,
    QRIcon,
    UsersIcon,
    UserIcon,
} from '@entur/icons'

import AggregatedChanges from './AggregatedChanges'

import './APIChangelogs.scss'
import IconCircle from './../../../src/components/IconCircle/IconCircle'

type APIChangelogsProps = {
    isTest?: boolean
}

const APIChangelogs: React.FC<APIChangelogsProps> = ({ isTest }) => {
    return (
        <div className="api-changelogs">
            <Heading2>API Changelogs</Heading2>
            <div className="api-changelogs__subsection">
                <Heading3 margin="bottom">Overview Changelogs</Heading3>
                <OpenServicesChangelogs isTest={isTest} />
                <PartnerServicesChangelogs isTest={isTest} />
                <InternalChangelogs isTest={isTest} />
            </div>
            <div className="api-changelogs__subsection">
                <Heading3 margin="bottom" className="latest-changes__heading">
                    Latest Changes
                </Heading3>
                <AggregatedChanges />
            </div>
        </div>
    )
}

type ItemWrapperProps = {
    showItem?: boolean
    icon: React.ReactNode
    title: string
    href: string
}

const ItemWrapper: React.FC<ItemWrapperProps> = (props) => {
    if (!props.showItem) {
        return null
    }

    return (
        <div className="api-changelogs__item-wrapper">
            <div className="api-changelogs__item-wrapper__icon">
                <IconCircle icon={props.icon} />
            </div>
            <div className="api-changelogs__item-wrapper__title">
                {props.title}
            </div>
            <span className="api-changelogs__item-wrapper__link">
                <Link href={props.href}>See changelog</Link>
                <RightArrowIcon />
            </span>
        </div>
    )
}

const OpenServicesChangelogs: React.FC<APIChangelogsProps> = ({ isTest }) => {
    return (
        <ExpandablePanel
            className="api-changelogs__expandable-panel"
            title={
                <>
                    <span className="api-changelogs-expandable-panel__title-icon">
                        <OrganizationIcon inline />
                    </span>
                    <span>Open Services</span>
                </>
            }
        >
            <div className="api-changelogs__grid">
                <ItemWrapper
                    icon={<InternationalIcon />}
                    title="Geocoder"
                    href="/geocoder-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<PositionIcon />}
                    title="National Stop Register"
                    href="/national-stop-register-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<MapIcon />}
                    title="Journey Planner"
                    href="/journey-planner-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<MapPinIcon />}
                    title="Real-Time Data"
                    href="/real-time-data-changelog"
                    showItem={isTest}
                />
            </div>
        </ExpandablePanel>
    )
}

const PartnerServicesChangelogs: React.FC<APIChangelogsProps> = ({
    isTest,
}) => {
    return (
        <ExpandablePanel
            className="api-changelogs__expandable-panel"
            title={
                <>
                    <span className="api-changelogs-expandable-panel__title-icon">
                        <ClosedLockIcon inline />
                    </span>
                    <span>Partner Services</span>
                </>
            }
        >
            <div className="api-changelogs__grid">
                <ItemWrapper
                    icon={<InternationalIcon />}
                    title="Dated Service Journey"
                    href="/dated-service-journey-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<FileIcon />}
                    title="National Order Database"
                    href="/national-order-database-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<PackageIcon />}
                    title="Products"
                    href="/products-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<PricelistIcon />}
                    title="Offers"
                    href="/offers-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<ShoppingCartIcon />}
                    title="Sales"
                    href="/sales-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<UsersIcon />}
                    title="Customers"
                    href="/customers-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<UserIcon />}
                    title="Personalisation"
                    href="/personalisation_client_changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<SeatIcon />}
                    title="Seating Services"
                    href="/seating-services-changelog"
                    showItem={isTest}
                />
                <ItemWrapper
                    icon={<ValidTicketIcon />}
                    title="Personnel Tickets"
                    href="/personnel-tickets-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<PackageIcon />}
                    title="Inventory"
                    href="/pages-inventory-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<TrainIcon />}
                    title="Seatmap"
                    href="/seatmap-changelog"
                    showItem={isTest}
                />
            </div>
        </ExpandablePanel>
    )
}

const InternalChangelogs: React.FC<APIChangelogsProps> = () => {
    return (
        <ExpandablePanel
            className="api-changelogs__expandable-panel"
            title={
                <>
                    <span className="api-changelogs-expandable-panel__title-icon">
                        <HomeIcon inline />
                    </span>
                    <span>Internal</span>
                </>
            }
        >
            <div className="api-changelogs__grid">
                <ItemWrapper
                    icon={<CityIcon />}
                    title="Organisations"
                    href="/organisations-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<QRIcon />}
                    title="Distribution Channels"
                    href="/distribution-channels-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<DiffIcon />}
                    title="Electronic Journal"
                    href="/pages-electronic-journal-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<MapPinIcon />}
                    title="Point-of-Sale Registry"
                    href="/pages-pos-registry-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<CardIcon />}
                    title="Travel-Card"
                    href="/pages-travel-card-changelog"
                    showItem={true}
                />
                <ItemWrapper
                    icon={<DatabaseIcon />}
                    title="Rolling-Stock"
                    href="/pages-rolling-stock-changelog"
                    showItem={true}
                />
            </div>
        </ExpandablePanel>
    )
}

export default APIChangelogs

import "../../../../../pages/intro/getstarted.scss";
import { Contrast, NavigationCard } from '@entur/layout';
import { NumberedList, ListItem, Paragraph } from '@entur/typography';
import tripSearch from "../../../../../pages/intro/images-getstarted/trip-search.svg";
import salesMain from "../../../../../pages/intro/images-getstarted/sales-main.svg";
import salesArrow from "../../../../../pages/intro/images-getstarted/sales-arrow.svg";
import salesArrowname from "../../../../../pages/intro/images-getstarted/sales-arrowname.svg";
import * as React from 'react';
export default {
  Contrast,
  NavigationCard,
  NumberedList,
  ListItem,
  Paragraph,
  tripSearch,
  salesMain,
  salesArrow,
  salesArrowname,
  React
};
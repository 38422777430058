export type Category = {
    title: string
    id: number
    active: boolean
    description: string
    apiService: string
    changelogPage: string
    categoryGroup: string
}

/**
 * Translates a category group into a more readable format.
 * @param categoryGroup - The category group to translate.
 * @returns The translated category group.
 */
export function translateCategoryGroup(categoryGroup: string): string {
    const translations = {
        PartnerServices: 'Partner Services',
        OpenServices: 'Open Services',
        Internal: 'Internal',
    }

    return translations[categoryGroup] || categoryGroup
}

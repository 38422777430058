type ChangeTypeOrChangeTypes = {
    type?: string
    types?: string[]
}

export const getSortedAndPrioritizedChangeTypes = (
    change: ChangeTypeOrChangeTypes,
): string[] => {
    const changetypePreferredOrder: ChangeTypeStrings[] = [
        'breaking',
        'deprecated',
        'bugfix',
        'newbeta',
        'new',
        'improvement',
        'internal',
    ]

    let types: ChangeTypeStrings[] = []

    if (change.types) {
        const specifiedTypes = change.types?.map((type) => toChangeType(type))
        changetypePreferredOrder.forEach((changetype) => {
            if (specifiedTypes?.includes(changetype)) {
                types.push(changetype)
            }
        })
    } else if (change.type) {
        types = [toChangeType(change.type)]
    } else {
        types = ['improvement']
    }

    types = types.slice(0, 3)

    return types
}

export type ChangeTypeStrings =
    | 'breaking'
    | 'deprecated'
    | 'bugfix'
    | 'new'
    | 'newbeta'
    | 'improvement'
    | 'internal'

export const toChangeType = (changeType?: string): ChangeTypeStrings => {
    const type = changeType ? changeType.toLowerCase() : 'unknown'
    switch (type) {
        case 'breaking':
        case 'breaking change':
            return 'breaking'
        case 'deprecation':
        case 'deprecated':
            return 'deprecated'
        case 'bugfix':
        case 'bugfixes':
            return 'bugfix'
        case 'new':
            return 'new'
        case 'newbeta':
            return 'newbeta'
        case 'improvement':
        case 'improvements':
            return 'improvement'
        case 'internal':
            return 'internal'
        default:
            return 'improvement'
    }
}

import React, { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'

import { Feature } from './Feature'

import { TextField } from '@entur/form'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    HeaderCell,
    DataCell,
} from '@entur/table'

const autocompleteSearch = debounce((query, callback) => {
    const url = `https://api.entur.io/geocoder/v1/autocomplete?text=${query}`
    fetch(url, { headers: { 'ET-Client-Name': 'entur-docs' } })
        .then((res) => res.json())
        .then((data) => {
            const { features } = data
            return callback(features)
        })
}, 400)

export default function Autosuggest(): JSX.Element {
    const [query, setQuery] = useState('')
    const [results, setResults] = useState<Feature[]>([])

    useEffect(() => {
        if (query) {
            autocompleteSearch(query, setResults)
        } else {
            setResults([])
        }
    }, [query])

    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ marginBottom: '1rem' }}>
                <TextField
                    label="Stop place or address"
                    value={query}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setQuery(e.currentTarget.value)
                    }
                    width="fluid"
                />
            </div>
            <Table width="fluid">
                <TableHead>
                    <TableRow>
                        <HeaderCell align="left">Label</HeaderCell>
                        <HeaderCell align="left">ID</HeaderCell>
                        <HeaderCell align="left">Categories</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((feature) => {
                        const { id, label, category } = feature.properties
                        return (
                            <TableRow key={id}>
                                <DataCell>{label}</DataCell>
                                <DataCell>{id}</DataCell>
                                <DataCell>{category.join(', ')}</DataCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

import cerseiChangelogData from '@pages/cersei/docs/changelog-cersei.json5';
import receiptChangelogData from '@pages/receipt/docs/changelog-receipt.json5';
import refundChangelogData from '@pages/refund/docs/changelog-refund.json5';
import orderChangelogData from '@pages/order/docs/changelog-order.json5';
import paymentChangelogData from '@pages/payment/docs/changelog-payment.json5';
import ticketDistributionChangelogData from '@pages/ticket-distribution/docs/changelog-ticket-distribution.json5';
import seatingArrangementChangelogData from '@pages/seating-arrangement/docs/changelog-seating-arrangement.json5';
import * as React from 'react';
export default {
  cerseiChangelogData,
  receiptChangelogData,
  refundChangelogData,
  orderChangelogData,
  paymentChangelogData,
  ticketDistributionChangelogData,
  seatingArrangementChangelogData,
  React
};
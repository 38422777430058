import React from 'react'

import { toChangeType } from '~/components/utils/changeTypeHelpers'
import { ChangelogEntry, ChangeProps, NormalisedChange } from './ChangelogEntry'
import {
    ChangeTypeFilters,
    allChangeTypeFilterStates,
    TypeFilterStates,
} from './ChangeTypeFilters'

import './Changelog.scss'

export const sortChangesByDate = (changes: ChangeProps[]): ChangeProps[] =>
    [...changes].sort((a, b) =>
        new Intl.Collator().compare(b.date || '', a.date || ''),
    )

export const parseChanges = (
    changelogData: ChangelogDataProps | ChangelogDataProps[],
): NormalisedChange[] => {
    const changes = Array.isArray(changelogData)
        ? changelogData.flatMap(
              (changelog: ChangelogDataProps) => changelog.changes,
          )
        : changelogData.changes

    return changes.map((change) => {
        if (change.types) {
            return {
                ...change,
                types: change.types?.map((type) => toChangeType(type)),
            }
        } else if (change.type) {
            return {
                ...change,
                types: [toChangeType(change.type)],
            }
        } else {
            return {
                ...change,
                types: ['improvement'],
            }
        }
    })
}

export const filterChanges = (
    changes: NormalisedChange[],
    typeFilter: TypeFilterStates,
): NormalisedChange[] =>
    changes.filter((change) => {
        for (const type of change.types) {
            if (typeFilter[type]) return true
        }
        return false
    })

type ChangelogDataProps = {
    team?: string
    changelog_page?: string
    api_service?: string
    changes: ChangeProps[]
}

export type ChangelogProps = {
    changelogData: ChangelogDataProps | ChangelogDataProps[]
}

const Changelog: React.FC<ChangelogProps> = ({ changelogData }) => {
    const [typeFilter, setTypeFilter] = React.useState(
        allChangeTypeFilterStates,
    )
    const parsedChanges = parseChanges(changelogData)
    const filteredChanges = filterChanges(parsedChanges, typeFilter)
    const sortedChanges = sortChangesByDate(filteredChanges)
    return (
        <div className="changelog">
            <ChangeTypeFilters
                typeFilter={typeFilter}
                setTypeFilter={setTypeFilter}
            />
            {sortedChanges.map((change, index) => (
                <ChangelogEntry change={change} key={index} />
            ))}
        </div>
    )
}

export default Changelog

import React from 'react'

import {
    CodeText,
    Heading2,
    Heading4,
    Link,
    Paragraph,
    SmallText,
} from '@entur/typography'

import './NewsBlog.scss'

export default function NewsBlog(): JSX.Element {
    return (
        <>
            <Heading2 className="news__header news__header-margin">
                News Blog
            </Heading2>
            <div className="news__entry">
                <Paragraph className="news__date">
                    <SmallText>December 1st 2021</SmallText>
                </Paragraph>
                <Heading4 className="news__title">
                    NSR NeTEx exports extended with ServiceFrame and FrameFrame
                    from January 2022
                </Heading4>
                <div className="news__text">
                    <Paragraph>
                        We have been testing NSR NeTEx exports with ServiceFrame
                        and FareFrame along with SiteFrame in Dev environment
                        for a while now. From December 2nd 2021, these exports
                        will be available in Staging environment and we are
                        planning to introduce ServiceFrame and FareFrame along
                        with SiteFrame in Production at the beginning of January
                        2022.
                    </Paragraph>
                    <Paragraph>
                        For more details on NeTEx exports, see{' '}
                    </Paragraph>
                    <Paragraph>
                        <Link href="https://storage.googleapis.com/marduk-test/tiamat/Current_latest.zip">
                            Entire Norway (Current stops + Scheduled StopPoints
                            and Passenger Assignment + FareZones +
                            GroupOfTariffZones) - Latest valid version of all
                            stops in Norway
                        </Link>
                    </Paragraph>
                    <Paragraph>
                        <Link href="https://storage.googleapis.com/marduk-test/tiamat/CurrentAndFuture_latest.zip">
                            Entire Norway (Current and future stops + Scheduled
                            StopPoints and Passenger Assignment + FareZones +
                            GroupOfTariffZones) - Current and future versions of
                            all stops in Norway
                        </Link>
                    </Paragraph>
                    <Paragraph>
                        <Link href="https://storage.googleapis.com/marduk-test/tiamat/Full_latest.zip">
                            (All stop versions, incl. outdated + Scheduled
                            StopPoints and Passenger Assignment + FareZones +
                            GroupOfTariffZones) - Current, future and outdated
                            versions of all stops in Norway
                        </Link>
                    </Paragraph>
                </div>
            </div>
            <div className="news__entry">
                <Paragraph className="news__date">
                    <SmallText>August 8th 2019</SmallText>
                </Paragraph>
                <Heading4 className="news__title">
                    Recommendation feature released for offers
                </Heading4>
                <div className="news__text">
                    <Paragraph>
                        Recommendations are an optional feature that aims to
                        recommend which offers you need to buy for various
                        situations, the purpose of this is to reduce the number
                        of offers the clients need to evaluate. Currently we
                        support recommendations based on three types of
                        commercial conditions, and cheapest offers.
                        Recommendations are the new way to &quot;mark&quot;
                        offers, and therefore TagSpec will be deprecated but
                        supported until November 1. 2019.
                    </Paragraph>
                    <Paragraph>
                        For more details on recommendations, see{' '}
                        <Link href="/pages-offers-docs-guides#recommendation">
                            guides under Offers
                        </Link>
                        .
                    </Paragraph>
                </div>
            </div>

            <div className="news__entry">
                <Heading4 className="news__title">
                    Improving this developer portal
                </Heading4>
                <Paragraph className="news__date">
                    <SmallText>August 7th 2019</SmallText>
                </Paragraph>
                <Paragraph className="news__text">
                    We are currently trying to improve the organisation and
                    content of the portal. This may lead to changes in the
                    URL&apos;s, things being moved around in the menu, and
                    eventually some restructuring of the content in each
                    article. Hopefully for the better :).
                </Paragraph>
            </div>

            <div className="news__entry">
                <Heading4 className="news__title">
                    URL&apos;s keep on being updated
                </Heading4>
                <Paragraph className="news__date">
                    <SmallText>June 3rd 2019</SmallText>
                </Paragraph>
                <Paragraph className="news__text">
                    For quite some time we have been working on moving our
                    endpoints to new URL&apos;s. The old endpoints journey
                    planner, real-time, geocoder and stop places will be turned
                    off on the 1st of July 2019. Make sure all your API requests
                    are pointing to <CodeText>api.entur.io</CodeText>.
                </Paragraph>
            </div>

            <div className="news__entry">
                <Heading4 className="news__title">A change of tech!</Heading4>
                <Paragraph className="news__date">
                    <SmallText>May 29th 2019</SmallText>
                </Paragraph>
                <Paragraph className="news__text">
                    We have just transitioned over to a new Docz based developer
                    portal and we are still working on its finer details. In the
                    meanwhile, everything previously available should still be
                    there, and we will be working on improving navigation,
                    content and visuals over the coming days and weeks, into
                    infinity - because there is always something new! Stay tuned
                    - stay patient!
                </Paragraph>
            </div>

            <div className="news__entry">
                <Heading4 className="news__title">
                    Good news! There are news!
                </Heading4>
                <Paragraph className="news__date">
                    <SmallText>May 9th 2019</SmallText>
                </Paragraph>
                <div className="news__text">
                    <Paragraph>
                        Hi and sorry! It&apos;s been hectic and we have not been
                        punching our news into the news feed. So let&apos;s get
                        started with some light news.
                    </Paragraph>
                    <Paragraph>
                        We have recently added &quot;Go Fjords&quot; to our data
                        exports. Go Fjords operates three bus lines between
                        Stavanger and respectively The Pulpit Rock
                        (Preikestolen), Øygardstøl hiking route to Kjerag, and
                        Tyssedal.
                    </Paragraph>
                    <Paragraph>
                        Many might also wonder: What about Vy? We are planning
                        to change the codespace of NSB, GJB, NBE and possibly
                        FLB over to new VY* codespaces. However, due to the
                        relatively large risks of something going wrong, we are
                        holding off for a while. All in good time, eh?
                    </Paragraph>
                    <Paragraph>
                        A list of current, future and obsolete codespaces can be
                        found here:{' '}
                        <Link href="https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/637370434/List+of+current+Codespaces">
                            List of current Codespaces
                        </Link>
                    </Paragraph>
                    <Paragraph>
                        We&apos;ll try to keep the news flowing from now on.
                        Again, very sorry about that.
                    </Paragraph>
                </div>
            </div>

            <div className="news__entry">
                <Heading4 className="news__title">
                    Entur for Developers
                </Heading4>
                <Paragraph className="news__date">
                    <SmallText>October 18th 2018</SmallText>
                </Paragraph>
                <div className="news__text">
                    <Paragraph>
                        Welcome to Entur for developers, a website facilitating
                        collaboration on solutions supporting simple and
                        sustainable transportation.
                    </Paragraph>
                    <Paragraph>
                        The site contains information useful for developers,
                        expressed expressed as quickstarts, guides, samples and
                        links to more documentation.
                    </Paragraph>
                    <Paragraph>
                        Several Web APIs are open to consumers for all purposes.
                        The other APIs are reserved for parties in the transport
                        sector with a contract. Streaming APIs are also
                        available.
                    </Paragraph>
                    <Paragraph>
                        For data consumers that need complete data sets, you
                        will find files available for download.
                    </Paragraph>
                    <Paragraph>
                        Source code is provided for all parties interested in
                        creating software using our APIs.
                    </Paragraph>
                    <Paragraph>
                        For feedback on the site or content, please contact{' '}
                        <Link href="mailto:kollektivdata@entur.org">
                            kollektivdata@entur.org
                        </Link>
                        . For contract users, send feedback as agreed.
                    </Paragraph>
                </div>
            </div>
        </>
    )
}

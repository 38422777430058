import React from 'react'
import { Tag } from '@entur/layout'
import { colors } from '@entur/tokens'
import {
    BugIcon,
    NewIcon,
    WarningIcon,
    ConfigurationIcon,
    LogoPositiveIcon,
} from '@entur/icons'
import { toChangeType } from '~/components/utils/changeTypeHelpers'

export type ChangeTypeProps = {
    changeType?: string
    className?: string
}

export const ChangeType: React.FC<ChangeTypeProps> = ({
    changeType,
    className,
}) => {
    const normalisedChangeType = toChangeType(changeType)

    switch (normalisedChangeType) {
        case 'breaking':
            return <BreakingChanges className={className} />
        case 'deprecated':
            return <Deprecated className={className} />
        case 'bugfix':
            return <Bugfixes className={className} />
        case 'new':
            return <New className={className} />
        case 'newbeta':
            return <NewBeta className={className} />
        case 'improvement':
            return <Improvements className={className} />
        case 'internal':
            return <Internal className={className} />
        default:
            return <Improvements className={className} />
    }
}

const Bugfixes = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <BugIcon inline style={{ color: colors.validation.canary }} />
            Bugfixes
        </Tag>
    )
}

const New = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <NewIcon inline style={{ color: colors.validation.mint }} />
            New
        </Tag>
    )
}

const NewBeta = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <NewIcon inline style={{ color: colors.validation.mint }} />
            New - Beta
        </Tag>
    )
}

const Improvements = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <ConfigurationIcon
                inline
                style={{ color: colors.validation.sky }}
            />
            Improvements
        </Tag>
    )
}

const BreakingChanges = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <WarningIcon inline style={{ color: colors.validation.lava }} />
            Breaking changes
        </Tag>
    )
}

const Deprecated = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <WarningIcon inline style={{ color: colors.validation.lava }} />
            Deprecated
        </Tag>
    )
}

const Internal = ({ className }: { className?: string }) => {
    return (
        <Tag className={className}>
            <LogoPositiveIcon
                inline
                style={{
                    color: colors.brand.blue,
                    marginRight: 8,
                    marginBottom: -1.5,
                }}
            />
            Internal
        </Tag>
    )
}

import React from 'react'
import './IconCircle.scss'

export default function IconCircle({
    icon,
}: {
    icon: React.ReactNode
}): JSX.Element {
    return <div className="icon-circle"> {icon} </div>
}

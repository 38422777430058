import { Link } from 'gatsby';
import { SuccessButton } from '@entur/button';
import { ExternalIcon } from '@entur/icons';
import { SubParagraph, LeadParagraph } from '@entur/typography';
import * as React from 'react';
export default {
  Link,
  SuccessButton,
  ExternalIcon,
  SubParagraph,
  LeadParagraph,
  React
};
import React from 'react'

import { formatDateOrQuarter } from '../utils/dateFormatter'

import { Heading4, SmallText } from '@entur/typography'

import { ChangeType } from './ChangeTypes'
import { Environments } from './Environments'
import { ReactMarkdownOrHtml } from './ReactMarkdownOrHtml'

import './ChangelogEntry.scss'
import {
    ChangeTypeStrings,
    getSortedAndPrioritizedChangeTypes,
} from '~/components/utils/changeTypeHelpers'

export type ChangeProps = {
    api_service?: string
    version?: string
    /** Will not be used if types field is used **/
    type?: string
    /** If present, this will be override anything passed in from type field **/
    types?: string[]
    environments?: string
    date?: string
    jira_issue?: string
    description: string
}

export type NormalisedChange = {
    api_service?: string
    version?: string
    types: ChangeTypeStrings[]
    environments?: string
    date?: string
    jira_issue?: string
    description: string
}

type ChangelogEntryProps = {
    change: ChangeProps
}

export const ChangelogEntry: React.FC<ChangelogEntryProps> = ({ change }) => {
    const types = getSortedAndPrioritizedChangeTypes(change)

    return (
        <div className="changelog__entry">
            <div className="changelog__version-and-date">
                <Heading4 margin="bottom">{change.version}</Heading4>
                <SmallText>{formatDateOrQuarter(change.date)}</SmallText>
            </div>
            <ReactMarkdownOrHtml
                className="changelog__description"
                content={change.description}
            />
            <div className="changelog__changetype-and-environments">
                {types.map((type, index) => (
                    <ChangeType
                        changeType={type}
                        className="changelog__changetype"
                        key={index}
                    />
                ))}
                <div>
                    <Environments environments={change.environments} />
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { FilterChip } from '@entur/chip'
import './ChangeTypeFilters.scss'

export interface TypeFilterStates {
    [key: string]: boolean
}

export const allChangeTypeFilterStates: TypeFilterStates = {
    breaking: true,
    deprecated: true,
    bugfix: true,
    improvement: true,
    new: true,
    newbeta: true,
    internal: true,
}

export const ChangeTypeFilters = ({
    typeFilter,
    setTypeFilter,
    filtersToShow = allChangeTypeFilterStates,
}: {
    typeFilter: TypeFilterStates
    setTypeFilter: (typeFilterStates: TypeFilterStates) => void
    filtersToShow?: TypeFilterStates
}): JSX.Element => {
    return (
        <div className="changetype-filters">
            {filtersToShow.breaking && (
                <FilterChip
                    value="breaking"
                    checked={typeFilter.breaking}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            breaking: !typeFilter.breaking,
                        })
                    }
                >
                    Breaking changes
                </FilterChip>
            )}
            {filtersToShow.deprecated && (
                <FilterChip
                    value="deprecated"
                    checked={typeFilter.deprecated}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            deprecated: !typeFilter.deprecated,
                        })
                    }
                >
                    Deprecated
                </FilterChip>
            )}
            {filtersToShow.improvement && (
                <FilterChip
                    value="improvement"
                    checked={typeFilter.improvement}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            improvement: !typeFilter.improvement,
                        })
                    }
                >
                    Improvements
                </FilterChip>
            )}
            {filtersToShow.bugfix && (
                <FilterChip
                    value="bugfix"
                    checked={typeFilter.bugfix}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            bugfix: !typeFilter.bugfix,
                        })
                    }
                >
                    Bugfixes
                </FilterChip>
            )}
            {filtersToShow.new && (
                <FilterChip
                    value="new"
                    checked={typeFilter.new}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            new: !typeFilter.new,
                        })
                    }
                >
                    New
                </FilterChip>
            )}
            {filtersToShow.newbeta && (
                <FilterChip
                    value="newbeta"
                    checked={typeFilter.newbeta}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            newbeta: !typeFilter.newbeta,
                        })
                    }
                >
                    New - Beta
                </FilterChip>
            )}
            {filtersToShow.internal && (
                <FilterChip
                    value="internal"
                    checked={typeFilter.internal}
                    onChange={() =>
                        setTypeFilter({
                            ...typeFilter,
                            internal: !typeFilter.internal,
                        })
                    }
                >
                    Internal
                </FilterChip>
            )}
        </div>
    )
}

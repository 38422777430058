import "../../../../../pages/intro/frontpage.scss";
import { Contrast, NavigationCard } from '@entur/layout';
import frontpagePhoto from "../../../../../pages/intro/Entur_frontpage.jpg";
import { LeadParagraph, Heading3, Heading2, Paragraph, Heading1, Link } from '@entur/typography';
import * as React from 'react';
export default {
  Contrast,
  NavigationCard,
  frontpagePhoto,
  LeadParagraph,
  Heading3,
  Heading2,
  Paragraph,
  Heading1,
  Link,
  React
};
import seatingManagerChangelog from '@pages/seating-manager/docs/changelog-seating-manager.json5';
import scheduledStockChangelog from '@pages/scheduled-stock/docs/changelog-scheduled-stock.json5';
import rollingStockChangelog from '@pages/rolling-stock/docs/changelog-rolling-stock.json5';
import seatingOverridesChangelog from '@pages/seating-overrides/docs/changelog-seating-overrides.json5';
import * as React from 'react';
export default {
  seatingManagerChangelog,
  scheduledStockChangelog,
  rollingStockChangelog,
  seatingOverridesChangelog,
  React
};